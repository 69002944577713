.navbar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column; /* Keeps stacking behavior for mobile */
  align-items: center;
  padding: 1rem 2rem;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.navbar .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.navbar .logo .party {
  background: linear-gradient(45deg, white 0%, rgb(255, 0, 221) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-size: 2rem;
  text-align: center;
  white-space: nowrap;
}

.navbar .nav-links {
  display: flex;
  flex-direction: row; /* Aligns 'Mint' and 'About' vertically under the logo */
  align-items: center;
  justify-content: center;
  margin: 0px; 
  margin-top: 5px;
}

.navbar a {
  justify-content: center;
  color: white;
  text-decoration: none;
  font-size: 1rem;
}

.navbar .nav-right {
  position: absolute;
  justify-content: center;
  right: 20px; /* Positions the button on the right */
  top: 10px; /* Adjust top margin to align properly */
}

.navbar .nav-right a.app-button {
  background: linear-gradient(90deg, rgba(102,94,255,1) 0%, rgb(217, 6, 108) 100%);
  color: #fff;
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  padding: 1em 2em;
  transition: transform 0.3s;
}

.navbar .nav-right a.app-button:hover {
  transform: scale(1.1);
}

@media (max-width: 767px) {
  .navbar .nav-right {
    position: static; /* Return the button to the flow of the document */
    order: 3; /* Ensure it appears below other content */
    width: 100%; /* Take full width to center the button */
    text-align: center; /* Center the button text */
    margin-top: 1rem; /* Add some space above the button */
  }

  .navbar .nav-right a.app-button {
    padding: 0.5em 1em; /* Adjust padding for smaller screens */
  }
}

@media (min-width: 768px) {
  .navbar {
    flex-direction: row; /* Allows horizontal layout for larger screens */
    justify-content: space-between; /* Spreads elements out */
    align-items: center; /* Aligns items vertically */
  }

  .navbar .container {
    flex-direction: row; /* Horizontal layout */
    justify-content: space-between;
    align-items: center;
  }

  .navbar .nav-links {
    flex-direction: row; /* Keeps 'Mint' and 'About' stacked */
    align-items: center;
  }
}
