/* NFTPage.css */

.nft-lookup {
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #000000; /* Dark background */
  color: #FFFFFF; /* Bright text for contrast */
}

.nft-lookup input[type='number'] {
  padding: 10px;
  background-color: #252525;
  border: 2px solid #FF00FF; /* Vibrant, neon-like border */
  color: #FFFFFF;
  margin-bottom: 10px;
  width: 300px; /* Fixed width */
  font-family: 'Press Start 2P', cursive; /* Retro, pixelated font */
}

.nft-lookup button {
  padding: 10px 20px;
  margin-bottom: 80px;
  background-color: #00FFFF; /* Cyan background */
  border: none;
  color: #000000;
  cursor: pointer;
  font-family: 'Press Start 2P', cursive;
}

.nft-item {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally in the flex container */
  justify-content: center; /* Center items vertically in the flex container */
  text-align: center; /* Ensures text within the div is centered */
}

.nft-name {
  line-height: 1.6;
}

.nft-image {
  max-width: 80%; /* Ensures the image is responsive and fits its container */
  height: auto; /* Maintains the aspect ratio of the image */
  border: 3px solid #FFFFFF; 
  margin-bottom: 20px;
}

.nft-lookup h3 {
  margin: 10px 0;
  font-family: 'Press Start 2P', cursive;
}

.nft-loading {
  color: #FFFFFF; 
  font-size: 16px;
}

.nft-error {
  color: #FF0000; /* Bright red for visibility */
  margin-top: 20px; /* Increase top margin to push it lower */
  font-size: 16px;
}

.nft-personality {
  color: #FFFFFF; /* Set to white or any appropriate color */
  font-size: 14px;
  margin-top: 10px;
  text-align: justify; /* Justifies the text for better readability */
  line-height: 1.6; /* Increases line spacing for readability */
  max-width: 90%; /* Adjust the maximum width for smaller screens */
}

.sprite-animation {
  margin-top: 30px;
  width: 256px; /* Width of one frame */
  height: 256px; /* Height of one frame */
  background-repeat: no-repeat;
  background-size: 1280px 256px; /* Assuming 5 frames at 256px each */
  animation: sprite-animation-steps 4s steps(1) infinite;
  image-rendering: pixelated; /* Keeps the pixel art style crisp */
  margin-bottom: 25vh;
}

@keyframes sprite-animation-steps {
  0% { background-position: -768px; } /* Frame 3 */
  14.2857% { background-position: -512px; } /* Frame 2 */
  28.5714% { background-position: -256px; } /* Frame 1 */
  42.8571% { background-position: -256px; transform: scaleX(-1); } /* Frame 1 flipped */
  57.1429% { background-position: -512px; transform: scaleX(-1); } /* Frame 2 flipped */
  71.4286% { background-position: -768px; transform: scaleX(-1); } /* Frame 3 flipped */
  85.7143% { background-position: -512px; transform: scaleX(-1); } /* Frame 2 flipped */
  100% { background-position: -256px; transform: scaleX(-1); } /* Frame 1 flipped */
}



