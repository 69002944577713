.footer {
  position: fixed; /* Fixed position to keep footer at the bottom */
  bottom: 0;
  left: 0;
  right: 0; /* Ensure the footer extends full width */
  padding: 1rem 2rem;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex; /* Use flexbox for layout */
  justify-content: center; /* Center children horizontally */
  align-items: center; /* Center children vertically */
  z-index: 100;
}

.footer .container {
  display: flex; /* Use flexbox for layout */
  justify-content: center; /* Center children horizontally */
  align-items: center; /* Center children vertically */
  width: 100%; /* Full width to ensure centering within the footer */
  flex-wrap: wrap; /* In case of multiple items, they can wrap */
}

.footer a {
  margin: 0 10px; /* Give some space between the links */
  white-space: nowrap; /* Prevents the text from wrapping */
}
