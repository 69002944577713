:root {
	--bg-color: #0a0a0a; /* Background color */
	--text-color: #fff; /* Primary text color */
	--accent-color: #646cff; /* Accent color for buttons and links */
	--nav-height: 50px; /* Navbar height */
  }
  
  * {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
  }
  
html, body {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	font-family: 'Press Start 2P', cursive; 
	background: var(--bg-color);
	color: var(--text-color);
  }
  
  body {
	background: black; /* Assuming the video covers the entire viewport */
  }
  
  a {
	color: var(--accent-color);
	text-decoration: none;
  }
  
  a:hover, a:focus {
	text-decoration: underline;
  }
  
  .button {
	font-family: 'Press Start 2P', cursive; /* This is a Google Font that mimics pixelated styles */
	font-size: 16px; /* Adjust size to match your design */
	color: #000;
	background-color: #ffcc00; /* Yellow color for the button background */
	border: none;
	border-radius: 0; /* Square corners for a retro look */
	padding: 10px 20px;
	margin: 10px;
	box-shadow: 3px 3px 0 #333; /* Simple shadow for depth */
	cursor: pointer;
	text-transform: uppercase;
	display: inline-block;
	text-align: center;
	-webkit-font-smoothing: none;
	text-rendering: optimizeLegibility;
  }
  
  .button:hover {
	background-color: #e6b800; 
  }
  
  .container {
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
  }


  .navbar {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem 2rem;
	background-color: rgba(0, 0, 0, 0.5); 
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); 
	z-index: 100; 
  }

  .navbar .container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
  }
  
  .navbar .logo {
	margin-left: 0; 
  	padding-left: 0; 
	color: white;
	font-weight: bold;
	font-size: 1.5rem;
  }

  .navbar .logo .party {
	background: linear-gradient(45deg, white 0%, rgb(255, 0, 221) 100%);
	-webkit-background-clip: text;
	background-clip: text;
	color: transparent;
  }

  .navbar .nav-links {
	margin-left: 50px;
	display: flex;
  	align-items: center;
  	margin-right: auto;
  }
  
  .navbar a {
	color: white;
	text-decoration: none;
	margin: 0 15px;
	font-size: 1rem;
  }
  
  .navbar a:hover {
	color: rgba(255, 0, 221);
  }

  .navbar .nav-right {
	display: flex;
	align-items: center;
	justify-content: flex-end;
  }

  .navbar .nav-right a.app-button {
	background-color: var(--accent-color); 
	padding: 0.5em 1em; 
	border-radius: 5px; 
	font: inherit;
	background: linear-gradient(90deg, rgba(102,94,255,1) 0%, rgb(217, 6, 108) 100%);
	color: #fff;
	border: none; /* Remove the border for a modern gradient effect */
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Soft shadow for a modern look */
	text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
	padding: 1em 2em;
	transition: transform 0.3s cubic-bezier(0.34, 1.56, 0.64, 1); /* Adjust the cubic-bezier values to get the desired effect */
  	transform: translateZ(0); /* Enable hardware acceleration for smoother animations */
  }

  .navbar .nav-right a.app-button:hover {
	transform: scale(1.1);
	background-color: darken(var(--accent-color), 10%); 
  }

  .navbar .nav-right a.app-button:active {
	transform: scale(0.95); /* Scales down a bit to simulate pressing */
	background-color: darken(var(--accent-color), 20%); /* Optionally darken the background color */
  }
  
  /* Footer style */
  .footer {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 1rem 2rem;
	background-color: rgba(0, 0, 0, 0.5); 
	color: white;
	text-align: center;
	z-index: 100;
  }
  
  /* Button styles */
  .button {
	padding: 10px 20px;
	margin: 10px;
	border: none;
	border-radius: 4px;
	background-color: #0061a8;
	color: white;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: bold;
	cursor: pointer;
	transition: background-color 0.3s ease;
  }
  
  .button:hover {
	background-color: #00497a;
  }

  .background {
	position: relative;
	flex-grow: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1;
  }
  
  .background video {
	position: relative; /* Set position to relative */
	top: 50px; /* Adjust the top position */
	width: 100vw;   /* 100% of the viewport width */
	height: 100vh;  /* 100% of the viewport height */
	object-fit: contain;
	position: fixed;
	top: 0;
	left: 0;
	z-index: -1; /* Send the video to the background */
  }

  .background img {
	width: 100%;
	height: 100%;
	object-fit: cover; /* This will ensure the image covers the whole area */
	}

  h1 {
	font-family: 'Press Start 2P', cursive; 
	color: #ffffff; 
	text-transform: uppercase;
	-webkit-font-smoothing: none;
	text-rendering: optimizeLegibility;
  }

  button.xmint-btn {
	font-family: 'Press Start 2P', cursive;
	font-size: 16px;
	background-color: transparent;
  }

  button.xmint-btn:hover {
	background-color: rgba(255, 0, 221);; /* Change background color on hover */
	color: #ffffff; /* Change text color on hover */
  }

  ul {
    list-style-type: disc; /* Classic bullet points */
    padding-left: 40px; /* Adjusts padding to allow space for bullet points */
    margin-top: 10px; /* Space above the list */
}

ul li {
    margin-bottom: 8px; /* Space between list items */
}

.main-content {
	padding-top: 60px; /* Adjust this value to match your navbar's height */
  }

.about-container {
	margin: '100px 50px';
}

